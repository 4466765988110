<template>
  <div class="users nj-grid">
    <div class="header">
      <v-form @submit.prevent="search()">
        <div class="d-flex flex-row">
          <nj-chip-text-input
            class="mr-2"
            default-text="Lote"
            v-model="form.lot"
          ></nj-chip-text-input>
          <nj-date-interval-input
            class="mr-2"
            label="Data de Cadastro"
            ref="creationIntervalInput"
            chip
            hide-details
            @change="chageCreationInterval"
            :default-start-at="defaultStartAt"
            :default-ends-at="defaultEndsAt"
          />
        </div>
      </v-form>
    </div>
    <v-data-table
      :headers="headers"
      :items="items"
      class="nj-table"
      hide-default-footer
      disable-pagination
      :loading="loading"
      loading-text="Buscando..."
      ref="dataTable"
    >
      <template v-slot:item.send_date="{ item }">
        {{ item.send_date | date }}
      </template>
      <template v-slot:item.status_message="{ item }">
        {{ item.status_message | status }}
      </template>
    </v-data-table>
  </div>
</template>
<script>
import moment from 'moment'
import Api from '@/resources/NajaSocialApi'
const defaultStartAt = moment().subtract(1, 'months')
const defaultEndsAt = moment()

export default {
  name: 'InboxMessages',

  watch: {
    form: {
      handler() {
        this.search()
      },
      deep: true,
    },
  },

  filters: {
    date: function (value) {
      return moment(value).format('DD/MM/YYYY HH:mm:ss')
    },
    status: function (value) {
      return {
        1: 'Enviada',
        2: 'Recebida',
        3: 'Respondida',
      }[value]
    },
  },

  data() {
    return {
      searchTerm: '',
      loading: false,
      items: [],
      defaultStartAt: defaultStartAt.format('DD/MM/YYYY'),
      defaultEndsAt: defaultEndsAt.format('DD/MM/YYYY'),
      form: {
        lot: null,
        send_date_gteq: null,
        send_date_lteq: null,
      },
      pagination: {
        current_page: 1,
        total_entries: 0,
      },
      headers: [
        { text: 'Código', value: 'id' },
        { text: 'Data', value: 'send_date' },
        {
          text: 'Destinatário',
          value: 'receiver',
        },
        { text: 'Status', value: 'status_message' },
        { text: 'Processo', value: 'event_type' },
        { text: 'Lote', value: 'lot' },
      ],
      footerProps: {
        itemsPerPageOptions: [10, 20, 30, -1],
      },
    }
  },

  mounted() {
    // this.fetch()
    // this.search()
  },

  methods: {
    chageCreationInterval(interval) {
      this.form.send_date_gteq = interval[0]
      this.form.send_date_lteq = interval[1]
    },

    search() {
      this.loading = true

      const query = {
        q: {
          s: ['created_at desc'],
          lot_eq: this.form.lot,
        },
      }

      if (
        this.form.send_date_gteq != null &&
        this.form.send_date_lteq != null
      ) {
        query.q.send_date_gteq = this.form.send_date_gteq
        query.q.send_date_lteq = this.form.send_date_lteq
      }

      Api.notifications
        .search(query)
        .then(response => {
          this.items = response.data.notifications
        })
        .catch(error => {
          if (error.response?.status === 400) {
            this.$root.$children[0].toast(
              error.response.data.error || error.response.data.warn
            )
          }
        })
        .finally(() => {
          this.loading = false
          this.$scrollTop()
        })
    },
  },
}
</script>

<style scoped>
.header .v-form {
  width: 100%;
  display: flex;
}
</style>
